<template>
  <v-app v-if="loggedIn">
    <dashboard-core-app-bar/>

    <dashboard-core-drawer/>

    <dashboard-core-view/>

    <dashboard-core-settings/>
  </v-app>

  <v-app v-else-if="!loggedIn">
    <Auth></Auth>
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',
    components: {
      Auth: () => import('../auth/Auth'),
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreSettings: () => import('./components/core/Settings'),
      DashboardCoreView: () => import('./components/core/View')
    },
    data: () => ({
      expandOnHover: false,
    }),
    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },
      currentUser() {
        return this.$store.state.auth.user;
      }
    },
  }
</script>
